@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Black.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 750;
  font-display: swap;
}

// Black Italic
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-BlackIt.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
  font-weight: 750;
  font-display: swap;
}

// Bold
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Bold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  font-display: swap;
}

// Bold Italics
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-BoldIt.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
  font-weight: 700;
  font-display: swap;
}

// Book (body + short headlines)
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Book.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

// Book Italics
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-BookIt.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

// Italics
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Italic.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
  font-weight:350;
  font-display: swap;
}

// Light(Capitals Large display font)
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Light.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

// Light Italics
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-LightIt.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

// Regular (headlines)
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Regular.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 350;
  font-display: swap;
}

// Semibold (secondary)
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-Semibold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  font-display: swap;
}

// Semibold Italics
@font-face {
  font-family: 'ToyotaType';
  src: url('#{$source-toyoya-type-root}/ToyotaType-SemiboldIt.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
  font-weight: 500;
  font-display: swap;
}