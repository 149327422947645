@import "~@jutro/theme/assets/sass/helpers";

.paymentButtonFooter {
  --gw-payment-button-footer-width: 100%;

  width: var(--gw-payment-button-footer-width);
  text-align: right;
  margin-top: var(--GW-LAYOUT-4);
}
.paymentButtonStyle {
  margin-left: var(--GW-LAYOUT-4);
}
.paymentCustomFooter {
  display: flex;
  justify-content: space-between;
}

h3[class='paymentTitle'] {
  margin-bottom: var(--GW-SPACING-5);
}
