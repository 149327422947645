.ErrorPageContainer {
    --gw-error-container-width: 70%;
    --gw-error-title-margin: 40px 0;
    --gw-error-sizing: 10px;
    width: var(--gw-error-container-width);
    margin: auto;
}

p.pageTitle {
    margin: var(--gw-error-title-margin);
    margin-bottom: 20px;
    font-size: xxx-large;
    font-weight: 450;
}

.icon {
    margin-right: var(--gw-error-sizing);
}

.assistanceText {
    padding: var(--gw-error-sizing) 0;
}