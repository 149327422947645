@import '~@jutro/theme/assets/sass/helpers';

.landingPageContainer {
  --gw-qnb-landing-page-content-width: 88%;
  --gw-qnb-landing-page-content-margin: 0 auto;

  @include gw-breakpoint-down(phonewide) {
    --gw-qnb-landing-page-content-width: auto;
    --gw-qnb-landing-page-content-margin: var(--GW-LAYOUT-4) var(-GW-LAYOUT-3)
    var(--GW-LAYOUT-6);
  }

  width: var(--gw-qnb-landing-page-content-width);
  margin: var(--gw-qnb-landing-page-content-margin);
}

.lobTypeToggle {
  --landing-page-toggle-width: 50%;
  --landing-page-toggle-padding: 25px 0;
  --landing-page-toggle-margin: auto;

  @include gw-breakpoint-down(phonewide) {
    --landing-page-toggle-width: 100%;
    --landing-page-toggle-margin: 0;
    --landing-page-toggle-padding: 25px;
  }

  width: var(--landing-page-toggle-width);
  padding: var(--landing-page-toggle-padding);
  margin: auto;
}

.lobTypeContainer {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

.retrieveQuoteContainer {
  --retrieve-quote-container-padding: 64px 16px 0;
  --retrieve-quote-container-width: 250px;

  height: auto;
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-direction: row;
  padding: var(--retrieve-quote-container-padding);
  width: var(--retrieve-quote-container-width);

  @include gw-breakpoint-down(phonewide) {
    width: 100%;
    margin: 0;
  }
}
