.scheduleItemsTable {
  margin-top: var(--GW-LAYOUT-6);
  padding: var(--GW-SPACING-3) 0;

  & th {
    text-align: left;
  }
}

.scheduleItemsTableOverflow {
  display: grid;
  overflow-x: auto;
}

td.gwTableCell {
  --table-cell-border-width: 1px;
  --table-border-color: var(--GW-BORDER-COLOR);
  --table-cell-width: 200px;

  width: var(--table-cell-width);
  vertical-align: top;
  border: var(--gw-quote-table-border);
}
