/*!
 * Material Design Icons by Google - https://github.com/google/material-design-icons
 * License - https://github.com/google/material-design-icons/blob/master/LICENSE (Apache License 2.0)
 * Fonts downloaded from https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp
 */

// stylelint-disable sh-waqar/declaration-use-variable

$material-icons-fonts-file-name: () !default;
$material-icons-fonts-file-name: map-merge(
  (
    'Material Icons': 'MaterialIcons-Regular',
    'Material Icons Round': 'MaterialIcons-Round',
    'Material Icons Outlined': 'MaterialIcons-Outlined',
    'Material Icons Sharp': 'MaterialIcons-Sharp',
    'Material Icons Two Tone': 'MaterialIcons-TwoTone',
  ),
  $material-icons-fonts-file-name
);

$material-icons-fonts-class-name: () !default;
$material-icons-fonts-class-name: map-merge(
  (
    material-icons: 'Material Icons',
    mi: 'Material Icons',
    mir: 'Material Icons Round',
    mio: 'Material Icons Outlined',
    mis: 'Material Icons Sharp',
    mitt: 'Material Icons Two Tone',
  ),
  $material-icons-fonts-class-name
);

$material-icons-font-size: 1.25rem;

@each $font-class-name, $font-name in $material-icons-fonts-class-name {
  .#{$font-class-name} {
    font-family: $font-name;
    font-weight: normal;
    font-style: normal;
    font-size: $material-icons-font-size;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

@each $font-name, $font-file in $material-icons-fonts-file-name {
  @font-face {
    font-family: $font-name;
    font-style: normal;
    font-weight: 400;
    src: url('#{$material-icons-root}/resources/#{$font-file}.woff2')
      format('woff2');
    font-display: swap;
  }
}

.linear-spin {
  animation: linear-spin 2s infinite linear;
}

.linear-pulse {
  animation: linear-spin 1s infinite steps(8);
}

@keyframes linear-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* stylelint-disable-next-line  no-invalid-position-at-import-rule */
@import './material-icons-content';

$icon-width: 1.25em;
.icon-stack {
  display: inline-block;
  height: 2rem;
  line-height: 2;
  position: relative;
  vertical-align: middle;
  width: ($icon-width * 2);
}

.icon-stack-1x,
.icon-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.icon-stack-1x {
  line-height: inherit;
}

.icon-stack-2x {
  font-size: 2rem;
}

.icon-inverse {
  color: #fff;
}
