@import "~@jutro/theme/assets/sass/helpers";
.dialogStyle {
    display: flex;
    padding: 0 0rem;
    align-items: center;
    justify-content: center;
    z-index: 101;
}
.alignEnd {
    display: flex;
    justify-content: flex-end;
}
@media only screen and (min-width: 450px) {
    .alignCenter {
        display: flex;
        justify-content: center;
    }
}
/* @media only screen and (max-width: 450px) {
    .alignCenter {
    }
} */
.marginGap {
    margin: 0.5rem;
}
.switchField {
    text-align: flex-end;
    align-items: flex-end;
    margin-bottom: 2rem;
}


// css for cookies modal

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
  }
  
  .modalContent {
    background: var(--gw-cookie-moda-background-colour, white);
    padding: var(--gw-cookie-modal-padding, 2rem);
    border-radius: var(--gw-cookie-modal-border-radius, 5px);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    @include gw-breakpoint-down(phone) {
        padding: 2rem;
        max-width: fit-content;
    }

  }
  
  .modalClose {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .cookiesDescText {
    color: var(--gw-cookie-modal-desc-colour, #000);
  }

  .cookie-policy-link {
    color: var(--gw-cookie-modal-cookie-policy-link-colour);
  }

  .cookieButtonsAlternateColour {
    background-color: var(--gw-alternate-button-background-colour) !important;
    color: #fff !important;
    &:hover:not(:disabled) {
        background-color: var(--gw-alternate-button-background-colour-hover) !important;
    }
  }
  