.SessionEndedContainer {
    --gw-session-ended-container-width: 70%;
    --gw-session-ended-title-margin: 40px 0;
    --gw-session-ended-sizing: 10px;
    width: var(--gw-session-ended-container-width);
    margin: auto;
}

h1.pageTitle {
    margin: var(--gw-session-ended-title-margin);
}

.icon {
    margin-right: var(--gw-session-ended-sizing);
}

.assistanceText {
    padding: var(--gw-session-ended-sizing) 0;
}