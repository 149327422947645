.gwAlert{
	--gw-uwissues-offering-name-padding-top: 2rem;
	--gw-uwissues-offering-name-border: 1px solid var(--GW-COLOR-WARNING-CONTRAST);
	--gw-alert-padding: 16px;
	--gw-waring-padding-right: 16px;
	--gw-uwissues-resolution-padding: 1em;
	--gw-uwissues-resolution-indent: 5em;
	--gw-alert-icon-padding: 0.5rem;
	--gw-alert-icon-border-radius: 50%;
	--gw-uw-action-buttons-margin-right: 0.5rem;

	background-color: var(--GW-COLOR-WARNING);
	border: 1px solid var(--GW-COLOR-WARNING-CONTRAST);
	padding: var(--gw-alert-padding);
	display: flex;

	.gwWarning {
		padding-right: var(--gw-waring-padding-right);
	}

	.gwWarningIcon {
		font-size: var(--gw-underwriting-issues-warning-icon-size);
	}

	.gwAlertContent {
		width: 100%;
	}

	.alertOptions {
		padding: var(--gw-uwissues-resolution-padding);
		padding-left: var(--gw-uwissues-resolution-indent);
		margin-bottom: 0;
	}

	.referToUWFormActions {
		display: flex;
	}

	.UWFormActions {
		display: flex;
		justify-content: space-between;
	}

	.UWFormConfirmStyle {
		font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
	}

	.withdrawButton {
		margin-right: var(--gw-uw-action-buttons-margin-right);
	}

	.underwriterButton {
		margin-right: var(--gw-uw-action-buttons-margin-right);
	}

	.cancelButton {
		margin-right: var(--gw-uw-action-buttons-margin-right);
	}
}