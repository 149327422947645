.SessionTimeoutContainer {
    --gw-session-timeout-container-width: 70%;
    --gw-session-timeout-title-margin: 40px 0;
    --gw-session-timeout-sizing: 10px;
    width: var(--gw-session-timeout-container-width);
    margin: auto;
}

p.pageTitle {
    margin: var(--gw-session-timeout-title-margin);
    font-size: xxx-large;
    font-weight: 450;
}

.icon {
    margin-right: var(--gw-session-timeout-sizing);
}

.assistanceText {
    padding: var(--gw-session-timeout-sizing) 0;
}
.assistanceTextBold {
    font-weight: 500;
}
.getAssistanceContainer {
    margin-bottom: 30px;
}
.warningText {
    margin-bottom: 20px !important;
}
.warningBoldText{
    color: var(--GW-BRAND-COLOR-2);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);;
}