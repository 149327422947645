.DeclinedUWContainer {
    --gw-declined-uw-container-width: 70%;
    --gw-declined-uw-title-margin: 40px 0;
    --gw-declined-uw-sizing: 10px;
    width: var(--gw-declined-uw-container-width);
    margin: auto;
}

h1.pageTitle {
    margin: var(--gw-declined-uw-title-margin);
}

.icon {
    margin-right: var(--gw-declined-uw-sizing);
}

.assistanceText {
    padding: var(--gw-declined-uw-sizing) 0;
}
.italicText {
    font-style: italic;
}