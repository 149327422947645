
.andBenefitsLabelList {
    margin-bottom: 40px;
    list-style-type: initial;
    padding-left: 18px;
}

.andQuoteProductInformationDocumentLink {
    text-decoration: underline;
    color: var(--GW-BRAND-COLOR-1);
    cursor: pointer;
}

