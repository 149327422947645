@import "~@jutro/theme/assets/sass/helpers";

.imageContainer {
  grid-template-areas: "label field";
}

.vehicleImage {
  --gw-vehicle-image-min-width: 360px;
  --gw-vehicle-image-width: 360px;
  --gw-vehicle-image-height: 270px;

  @include gw-breakpoint-down(phonewide) {
    --gw-vehicle-image-min-width: 100%;
  }

  min-width: var(--gw-vehicle-image-min-width);
  width: var(--gw-vehicle-image-width);
  height: var(--gw-vehicle-image-height);
  padding-bottom: var(--GW-SPACING-6);
}

.imageInfoContainer {
  grid-area: field;
}

.vinInfoText {
  margin-top: var(--GW-SPACING-6);
}

.vehicleLookupBottomItems {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  @include gw-breakpoint-up(tablet) {
    display: flex;
    align-items: center;
  }
}

.searchFieldClearButtonContainer {
  position: relative;
}

#searchButton {
    margin-left: -5px !important;
}

.gw-show-reg-hint-icon {
  color: var(--GW-TOOLTIP-ICON-COLOR);
    padding: 0;
    display: inline-flex;
    background-color: transparent;
    border: none;
    outline: none;
    position: relative;
    margin-right: 3px;
}