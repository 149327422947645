.facebookContent {
   display: flex;
   justify-content: center;
   padding: var(--GW-LAYOUT-8) 0;
}

.facebookShare {
   --gw-facebook-share-width: 300px;
   border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
   border-radius: var(--GW-BORDER-RADIUS);
   max-width: var(--gw-facebook-share-width);
   text-align: center;
   padding: var(--GW-LAYOUT-6);
}
