@import '~@jutro/theme/assets/sass/helpers';

.digitalWizard {
  --gw-wizard-page-section-gap: var(--GW-LAYOUT-8);
  --gw-wizard-page-buttons-bar-margin: 3rem;
  --gw-wizard-page-main-margin: 0 auto 4rem auto;
  --gw-wizard-page-main-tablet-margin: 0 auto var(--GW-LAYOUT-8) auto;
  --gw-wizard-page-main-areas-original: 'navigation   wizcontent';
  --gw-wizard-page-main-areas: var(--gw-wizard-page-main-areas-original);

  // Desktop variables
  --gw-wizard-page-main-width: 88%;
  --gw-wizard-page-template-sidebar-width: 25%;
  --gw-wizard-page-template-main-content-width: calc(
    100% - var(--gw-wizard-page-template-sidebar-width) -
      var(--gw-wizard-page-section-gap)
  );
  --gw-wizard-page-template-column-widths: var(
      --gw-wizard-page-template-sidebar-width
    )
    var(--gw-wizard-page-template-main-content-width);

  // Tablet variables
  --gw-wizard-page-main-tablet-width: 90%;
  --gw-wizard-page-template-sidebar-tablet-width: 11rem;
  --gw-wizard-page-template-main-content-tablet-width: calc(
    100% - var(--gw-wizard-page-template-sidebar-tablet-width) -
      var(--gw-wizard-page-section-gap)
  );
  --gw-wizard-page-template-tablet-column-widths: var(
      --gw-wizard-page-template-sidebar-tablet-width
    )
    var(--gw-wizard-page-template-main-content-tablet-width);

  // Phone variables
  --gw-wizard-page-main-phone-width: 100vw;
  --gw-wizard-page-template-main-content-phone-width: var(
      --gw-wizard-page-template-sidebar-tablet-width
    )
    var(--gw-wizard-page-main-phone-width);
  --gw-wizard-page-template-phone-column-widths: auto;

  display: grid;
  @media (min-width: 200px) and (max-width: 768px) {
    width: 100vw;
  }
  width: 100%;
  height: 100%;
  padding: var(--GW-BOX-SHADOW-FOCUS-RADIUS) var(--GW-BOX-SHADOW-FOCUS-RADIUS);
  grid-gap: var(--GW-LAYOUT-6);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'context'
    'main';
  align-items: start;

  @include gw-breakpoint-down(phonewide) {
    grid-gap: 0;
  }
}

.wizardMain {
  @include gw-breakpoint-down(phonewide) {
    --gw-wizard-page-main-width: var(--gw-wizard-page-main-phone-width);
    --gw-wizard-page-template-column-widths: var(
      --gw-wizard-page-template-phone-column-widths
    );
    --gw-wizard-page-main-areas: 'wizcontent';
  }
  @include gw-breakpoint-only(tablet) {
    --gw-wizard-page-section-gap: var(--GW-LAYOUT-6);
    --gw-wizard-page-main-margin: var(--gw-wizard-page-main-tablet-margin);
    --gw-wizard-page-main-width: var(--gw-wizard-page-main-tablet-width);
    --gw-wizard-page-template-column-widths: var(
      --gw-wizard-page-template-tablet-column-widths
    );
  }

  display: grid;
  grid-area: main;
  width: var(--gw-wizard-page-main-width);
  margin: var(--gw-wizard-page-main-margin);
  grid-gap: var(--GW-SPACING-6);
  grid-template-columns: var(--gw-wizard-page-template-column-widths);
  grid-template-areas: var(--gw-wizard-page-main-areas);
  align-items: start;
}

.wizardMainShowSidebar {
  --gw-wizard-page-main-areas: var(--gw-wizard-page-main-areas-original);

  overflow-x: scroll;
  grid-template-columns: var(
    --gw-wizard-page-template-main-content-phone-width
  );
}

.wizardContext {
  grid-area: context;
}

.wizardNavigation {
  grid-area: navigation;
}

.wizardContent {
  grid-area: wizcontent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wizardContentContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-12) var(--GW-LAYOUT-6);
    @include gw-breakpoint-down(phonewide) {
      padding: var(--GW-LAYOUT-6);
    }
  }
}

.wizardWidgets {
  margin-top: var(--GW-LAYOUT-6);
}

.navButtons {
  display: var(--gw-nav-buttons-container-display, flex);
  flex-direction: row;
  justify-content: space-between;
  padding-top: var(--GW-LAYOUT-8);
  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
  margin-top: var(--gw-wizard-page-buttons-bar-margin);

  @include gw-breakpoint-down(phonewide) {
    justify-content: center;
    flex-direction: column-reverse;
  }

  .navTransitionButtons {
    display: var(--gw-nav-transitions-buttons-display, grid);
    flex-direction: column-reverse;
    align-items: flex-start;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-template-areas: 'previous next';
    grid-gap: var(--GW-LAYOUT-6);
    margin-bottom: var(--GW-LAYOUT-8);

    @include gw-breakpoint-down(phonewide) {
      grid-gap: var(--GW-LAYOUT-2);
    }

    .nextbutton {
      grid-area: next;
      width: 100%;

      span {
        display: flex;
      }
    }
  }

  .navTransitionButtonOnlyNext {
    grid-template-columns: auto;
    grid-template-areas: 'next';
  }
}

/* #gw-wizard-Next-and-Previous-container {
  display: block;
}

.gw-quote-addons-page-next-and-previous-button-container {
  width: 100%;
}

.gw-quote-addons-page-next-button {
  width: 100%;
  margin-bottom: 20px;
} */