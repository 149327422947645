.stepsNav {
  display: flex;
  justify-content: center;
}

.navigationButton {
  --gw-nav-buttons-size: 16px;

  display: block;
  cursor: pointer;
  border-radius: 50%;
  background: inherit;
  text-align: inherit;
  width: var(--gw-nav-buttons-size);
  height: var(--gw-nav-buttons-size);
  margin: var(--GW-SPACING-1);
  padding: var(--GW-SPACING-2);
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  border-color: var(--GW-SEPARATOR-COLOR);

  &.active {
    position: relative;
    color: var(--GW-COLOR-SUCCESS-CONTRAST);
    background-color: var(--GW-BRAND-COLOR-1);
  }
}
