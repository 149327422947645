.productInputContainer {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    justify-content: center;
    grid-row-gap: var(--line-of-business-space);
    grid-template-areas:
        "postalCode"
        "startQuote"
        "retrieveQuote";
}

.postalCode {
    text-align: center;
    margin: auto;
    width: var(--line-of-business-item-width);
    grid-area: postalCode;
}

.postalCodeContainer {
    text-align: center;
    input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.startQuote {
    grid-area: startQuote;
    margin: auto;
}

.retrieveQuote {
    grid-area: retrieveQuote;
    text-align: center;
}