@import "~@jutro/theme/assets/sass/helpers";

.addressLookupBottomItems {
  @include gw-breakpoint-up(tablet) {
    display: flex;
    align-items: center;
  }
}

.searchFieldClearButtonContainer {
  position: relative;
}

.clearButton {
  --gw-close-button-position-top: 0;
  @include gw-breakpoint-down(tablet) {
    --gw-close-button-position-top: var(--GW-SPACING-8);
  }
  top: var(--gw-close-button-position-top);
  right: 0;
  position: absolute;
}

.addressLookupSearchButton {
  margin-left: var(--GW-SPACING-6);
}

.fieldContainer {
  grid-template-areas: ". field";
}
.fieldContent {
  grid-area: field;
}

