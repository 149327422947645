
$image-root: "../assets/images" !default;
$tya-car-image: "#{$image-root}/branding/tya/toyota_yaris.png" !default;

#AggPageContainer {
    --gw-agg-container-width: 70%;
    --gw-agg-title-margin: 40px 0;
    --gw-agg-sizing: 10px;
    width: var(--gw-agg-container-width);
    margin: auto;
}

.aggLowerMessageContainer {
  --gw-agg-container-width: 70%;
  --gw-agg-title-margin: 40px 0;
  --gw-agg-sizing: 10px;
  width: var(--gw-agg-container-width);
  margin: auto;
}

h1.pageTitle {
    margin: var(--gw-agg-title-margin);
}

.icon {
    margin-right: var(--gw-agg-sizing);
}

.assistanceText {
    padding: var(--gw-agg-sizing) 0;
}

#AggslogoImageContainer {
    margin: 0 auto;
}
#AggsLoaderContainer {
    margin: 0 auto;
    padding-bottom: 80px;
}

.AggsHeader {
    background-color: var(--gw-aggs-splash-screen-header-BG-colour);
    color: #ffffff;
    display: flex;
    position: relative;
    flex-direction: column;
}

#AggsHeaderText {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
}

#AggsSubSpinnerText {
  text-align: center;
}

.AggsCarImage {
    padding: 0 calc(40% - 9.5vw);
    margin-bottom: calc(-20vh + 12vh);
    overflow: visible;
    width: 100%;
}
.AggsLogoImage {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.AggMessageContainer {
    --gw-agg-container-width: 70%;
    --gw-agg-title-margin: 40px 0;
    --gw-agg-sizing: 10px;
    width: var(--gw-agg-container-width);
    margin: auto;
}

h1.pageTitle {
    margin: var(--gw-agg-title-margin);
}

#AggsContainer {
    min-height: 100vh;
}

  #AggsHeaderDivider {
    margin: 10px 0;
    opacity: 0.2;
    background-color: #ffffff;
  }
  #AggsCarImage {
    padding: 0 calc(40% - 9.5vw);
    margin-bottom: calc(-20vh + 12vh);
    overflow: visible;
    width: 100%;
  }
  #AggMessageContainer {
    margin-top: calc(20vh - 12vh);
  }
  #AggsHeaderTitle {
    font-size: 30px;
    @media (min-width: 640px) {
      font-size: 50px;
    }
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  #AggsHeaderSubtitle {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    @media (min-width: 640px) {
      font-size: 25px;
    }

    & p {
      margin: 0;
    }
  }
  #AggLowerMessageContainer {
    margin-top: 1vh;
  }
  #AggslogoImageContainer {
    margin: 0 auto;
  }
  #AggsLoaderContainer {
    margin: 0 auto;
    padding-bottom: 80px;
  }