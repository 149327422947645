.ValidationErrorWarningComponent {
    background-color: #f7ded8;
    padding: 16px;
}

.ValidationErrorWarningIconTextContainer {
    margin: 0;
}

.ValidationErrorWarningText {
    margin: 0 0 0 48px !important;
    padding: 0 !important;
}

.ValidationErrorWarningIconText { 
    margin-left: 16px;
    padding: 0 !important;
    color: var(--GW-COLOR-ERROR);
}



.mymarketingpreferencescontent {
    color: var(--GW-COLOR-ERROR);
}

.CarRegistrationNumberContent {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.horizontalLine {
    margin: 8px 0 8px 0 !important;
}

.yourVehicleHeader {
    font-weight: bold !important;
}

.marketingEmailsAndSmsMessagesRadio [role="radiogroup"]{
    label {
        margin-top: var(--GW-SPACING-3);
    }
}