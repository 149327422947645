@import "~@jutro/theme/assets/sass/helpers";

.andMandateModal {
    width: 50%;
}

#DDMandateButtonContainer>Button {
    margin-right: 8px;
    @include gw-breakpoint-down(phone) {
        margin-bottom: 7px;
    }
}

.ddMandateViewContainer {
    background-color: #f1f1f1;
    padding: 10px;
    margin-left: -10px;
    span {
        font-size: var(--gw-font-size-mandate-label) !important;
        font-weight: var(--gw-mandate-input-font-weight);
    }
    h4 {
        margin: 10px 0px;
    }
    .ddMandateViewCompanyAddressContainer {
        margin-bottom: 5px;
        span {
            font-weight: var(--gw-mandate-input-font-weight);
        }
    }
    .ddMandateInstructionContainer {
        margin-bottom: 10px;
        p#directDebitInstruction2 {
            margin-bottom: 5px;
        }
    }
    .ddMandateDateContainer {
        margin-bottom: 20px;
        label span, div {
            margin-bottom: 0;
        }
    }
    .ddMandateGuranteeContainer {
        p:not(#ddMandateGurantee4) {
            margin-bottom: 10px;
            font-size: var(--GW-FONT-SIZE-BODY);
        }
    }
}

#ddDetailsTitle {
    font-size: var(--gw-font-size-mandate-label);
}

#directDebitDateContainer * {
    font-weight: normal;
}

.ddMandateDateDetailsInputField {
    label span, div {
        font-size: var(--gw-font-size-mandate-label);
        font-weight: var(--gw-mandate-input-font-weight);
    }
}

.ddConfirmationDetailsInputField {
    label span, div {
        font-size: var(--gw-font-size-mandate-label) !important
    }
    label span {
        font-weight: 700 !important
    }
}

#directDebitDetailsConfirmationContainer {
    #ddDetailsAccountHolderName2 {
        font-size: var(--GW-FONT-SIZE-LABEL) !important;
    }
}

div#ddDetailsSortCode, div#ddDetailsBankAccNum, div#ddDetailsAccountHolderName2, div#ddDetailsAccountHolderName1 {
    font-weight: 400;
    margin-bottom: 10px;
}

.ddMandateViewCompanyAddressContainer {
    *:not(#ddDetailsBankAddressTitle) {
        font-weight: 400;
        font-size: var(--GW-FONT-SIZE-LABEL);
    }
}

.ddMandateViewAddressContainer {
    display: grid;
    margin-bottom: 20px;
}

.ddDetailsConfirmationContainer {
    margin-bottom: -20px;
    span, div {
        font-size: var(--GW-FONT-SIZE-BODY);
    }
    label span {
        font-size: var(--gw-font-size-mandate-label);
        font-weight: var(--gw-mandate-input-font-weight);
    }
}

.andMandatePopUpContainer {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid;
    margin-bottom: 5px;
}

.andMandatePopupColumn {
    padding: 10px;
    width: 50%;
    @include gw-breakpoint-down(tablet) {
        width: 100%;
    }
}

.and-table {
    border-collapse: collapse;
    tr td {
        border: 1px solid black;
        min-width: 30px;
        height: 30px;
    }
}

.gw-bold-text {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.mandateModalFooter {
    > div {
        float: left;
    }
}

.gw-mb10 {
    margin-bottom: 10px;
}

.gw-mb5 {
    margin-bottom: 5px;
}

.gw-mt15 {
    margin-top: 15px;
}

.and-w-100 {
    width: 100%
}

.and-d-flex {
    display: flex;
}

.and-bordered {
    border: 1px solid;
}

.and-pad-5 {
    padding: 5px;
}

@media print {
    .andMandateModal {
        width: 150%;
        top: 5%;        
    }
    
    .andMandatePopupColumn {
        width: 50%;
    }
}