#loginPortalBtn, #continueBtn, #activatePortalBtn {
    margin-top: 10px;
    display: block;
    border: solid 1px !important;
    padding-top: 10px;
    padding-bottom: 30px;
}

#loginPortalBtn, #continueBtn, #activatePortalBtn {
    background: var(--GW-BRAND-COLOR-1);
    color: var(--GW-TEXT-COLOR-3);
}
  
.loginPortalButtonContainer {
    display: block;
    height: 95px;
    padding-top: 30px;
}

.activatePortalBtnContainer {
    display: flex;
    justify-content: center;

}
.policyOptions{
    @media (min-width:350px ) and (max-width: 767px) {
        padding-left: 0;
    }
}