@import "~@jutro/theme/assets/sass/helpers";
.andCoverage {
    .andLabel {
      margin: 0;
      padding-left: 5px;
    }
  
    .andCoverageMainLabel {
      font-size: large;
    }

    a{
      color: var(--GW-BRAND-COLOR-1);
      &:hover {
        color: var(--GW-LINK-COLOR-HOVER);
      }
    }
  }

  .andCoverageNameLabel {
    margin-top: -3px !important;
    color: var(--gw-coverage-highlighted-text-colour);
  }

  .andCoverageNameLabelEcccKfi {
    margin-top: -3px !important;
    strong {
      color: var(--gw-coverage-highlighted-text-colour);
    }
  }

  .showOptionsContainer {
    display: flex;
    svg {
      font-size: xx-large;
      cursor: pointer;
    }
    .showBreakdownOptionsButton, .showBreakdownOptionsButton:active:focus:hover:not(.disabled), .showBreakdownOptionsButton:hover:not(.disabled), .showBreakdownOptionsButton:active:not(.disabled), .showBreakdownOptionsButton:focus:not(.disabled) {
      border: none;
      background: none;
      box-shadow: none;
      color: var(--GW-BRAND-COLOR-2);
      font-size: large;
      font-weight: bold;
      padding-left: 5px;
    }
  }

  @media (min-width: 0px) and (max-width: 768px) {
    .seeAllBreakdownOptions {
      font-size: var(--GW-FONT-SIZE-BODY) !important;
    }
  }

  .covBenefitContainer {
    display: flex;
    padding-left: 10px;
    align-items: flex-start;
    margin-bottom: 15px;
    p {
      margin-left: 5px;
      width: 90%;
      margin-block-end: 0;
      margin-left: 8px;
      strong {
        color: var(--gw-breakdown-coverage-bullet-point-title-colour, var(--GW-BRAND-COLOR-2))
      }
    }
  }

  .andChoice {
    display: flex;
    padding: 16px;
    margin-top: 8ps;
    margin-bottom: var(--GW-SPACING-4);
    border-radius: 2px;
    border: 1px solid;
    border-color: var(--gw-quote-page-border-colour);

    input[type="radio"] {
      display: flex;
      appearance: none;
      margin-right: 0.5em;
      margin-top: 3px;
      width: var(--GW-RADIO-BUTTON-SIZE);
      height: var(--GW-RADIO-BUTTON-SIZE);
      border: 1px solid var(--GW-SELECTOR-SELECTED-BACKGROUND-COLOR);
      border-radius: 50%;
      place-content: center;
  
    }
    input[type="radio"]::before{
      content: "";
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  
    input[type="radio"]:checked::before{
      background-color: var(--GW-SELECTOR-SELECTED-BACKGROUND-COLOR);
      border-radius: 50%;
    }
  
  }
  .andChoiceRedBorder {
    border-color: var(--GW-COLOR-ERROR);
    background-color: var(--GW-COLOR-ERROR-BACKGROUND-TINT);
  }

  .andCoverageIconContainer{
    display:flex; 
    flex-direction: row;
    margin: 25px 0 10px 0;
  }

  .andCovPretext {
    margin-bottom: 10px !important;
  }
  .andCovBreakdwnPretext {
    font-size: large !important;
    font-weight: bold;
    margin-bottom: 10px !important;
  }

  .andCoverageIcon {
    height: 60px; 
    width: 60px; 
    margin-right: 15px;
  }

  .andCoverageTitle {
    font-weight: bold;
    font-size: medium;
  }
  
  .coverageAmount {
    color: var(--gw-coverage-highlighted-text-colour);
    margin-left: 4px;
    display: flex;
    font-weight: bold;
    span {
      margin-left: 5px;
    }
  }
  .coverageAmountText{
    margin-left: 4px !important;
  }

  .andIconCoverageTitle{
    padding: 15px 0px; 
    font-size: x-large;
    color: var(--gw-coverage-title-text-colour) !important;
  }

  .BreakdownSVGIcon {
    .covIconLayer1 {
      fill: #f7f7f7;
    }
    .covIconLayer2 {
      fill: #efefef;
    }
    .covIconLayer3 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer4 {
      fill: #fff;
    }
    .covIconLayer5 {
      fill: #fff;
    }
    .covIconLayer6 {
      fill: #fff;
    }
    .covIconLayer7 {
      fill: #fff;
    }
    .covIconLayer8 {
      fill: #fff;
    }
    .covIconLayer9 {
      fill: #fff;
    }
    .covIconLayer10 {
      fill: #fff;
    }
    .covIconLayer11 {
      fill: var(--gw-coverage-icon-colour);
    }
  }
  .DrivingAbroadSVGIcon {
    .covIconLayer1 {
      fill: #f7f7f7;
    }
    .covIconLayer2 {
      fill: #efefef;
    }
    .covIconLayer3 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer4 {
      fill: #fff;
    }
    .covIconLayer5 {
      fill: #fff;
    }
    .covIconLayer6 {
      fill: #fff;
    }
    .covIconLayer7 {
      fill: #fff;
    }
    .covIconLayer8 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer9 {
      fill: #fff;
    }
    .covIconLayer10 {
      fill: #fff;
    }
    .covIconLayer11 {
      fill: #fff;
    }
    .covIconLayer12 {
      fill: #fff;
    }
    .covIconLayer13 {
      fill: #fff;
    }
    .covIconLayer14 {
      fill: #fff;
    }
    .covIconLayer15 {
      fill: #fff;
    }
    .covIconLayer16 {
      fill: #fff;
    }
    .covIconLayer17 {
      fill: #fff;
    }
    .covIconLayer18 {
      fill: #fff;
    }
    .covIconLayer19 {
      fill: #fff;
    }
    .covIconLayer20 {
      fill: #fff;
    }
  }
  
  .LegalExpensesSVGIcon {
    .covIconLayer1 {
      fill: #f7f7f7;
    }
    .covIconLayer2 {
      fill: #efefef;
    }
    .covIconLayer3 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer4 {
      fill: #fff;
    }
  }
  
  .NCDPSVGIcon {
    .covIconLayer1 {
      fill: #f7f7f7;
    }
    .covIconLayer2 {
      fill: #efefef;
    }
    .covIconLayer3 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer4 {
      fill: #fff;
    }
    .covIconLayer5 {
      fill: #fff;
    }
    .covIconLayer6 {
      fill: #fff;
    }
    .covIconLayer7 {
      fill: #fff;
    }
    .covIconLayer8 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer9 {
      fill: var(--gw-coverage-icon-colour);
    }
  }
  
  .TrailerSVGIcon {
    .covIconLayer1 {
      fill: #f7f7f7;
    }
    .covIconLayer2 {
      fill: #efefef;
    }
    .covIconLayer3 {
      fill: #fff;
    }
    .covIconLayer4 {
      fill: var(--gw-coverage-icon-colour);
    }
  }
  .CourtesyCarSVGIcon {
    .covIconLayer1 {
      fill: #f7f7f7;
    }
    .covIconLayer2 {
      fill: #efefef;
    }
    .covIconLayer3 {
      fill: var(--gw-coverage-icon-colour);
    }
    .covIconLayer4 {
      fill: #fff;
    }
    .covIconLayer5 {
      fill: #fff;
    }
    .covIconLayer6 {
      fill: #fff;
    }
    .covIconLayer7 {
      fill: #fff;
    }
    .covIconLayer8 {
      fill: #fff;
    }
    .covIconLayer9 {
      fill: var(--gw-coverage-icon-colour);
    }
  }
  