.stepContainer {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.navigationButton {
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border: 0;
    padding: 0;
}

.step {
    --gw-step-size: 0.5rem;
    margin-right: var(--GW-SPACING-3);

    &::before {
        content: '';
        display: inline-block;
        min-width: var(--gw-step-size);
        height: var(--gw-step-size);
        background-color: var(--GW-BRAND-COLOR-1);
        border-radius: 50%;
        vertical-align: middle;
    }
}

.notVisited::before {
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
}

.active::before {
    background-color: var(--GW-BRAND-COLOR-2);
}

.visited::before {
    background-color: var(--GW-BRAND-COLOR-1);
}
