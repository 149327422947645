@use 'sass:math';
@import "~@jutro/theme/assets/sass/helpers";

// Style variables
$cellWidth: math.div(76%, 6);

.andPNCDAppendixModal {
    width: 45%;
    @include gw-breakpoint-down(tablet) {
        width: 60%;
        
    }
    @include gw-breakpoint-down(phone) {
        left: 8%;
    }
}

.andPNCDAppendixPopUpContainer {
    @include gw-breakpoint-down(phone) {
        p, h3, li, span{
            font-size: 3vw !important;
        }
        h2 {
            font-size: 4vw;
        }
    }
}

.pncdAppendixHeaderContainer {
    margin-bottom: 40px;
    h2 {
        text-align: center;
    }
}

.pncdAppendixTable1Container {
    table {
        margin: 20px 0px;
        text-align: center;
        width: 60%;
        margin-left: 20%;
        font-size: 15px;
        border-collapse: collapse;
        thead {
            background-color: var(--GW-BRAND-COLOR-2);
            color: #fff;
            th {
                font-weight: 100;
                padding: 4px;
                border: 2px black solid;
            }
        }
        tbody {
            td {
                border: 2px black solid;
                padding: 5px;
            }
        }
    }
    @include gw-breakpoint-down(phone) {
        table {
            font-size: 10px;
        }
    }
}

.pncdAppendixTable2Container {
    p:not(:last-child) {
        margin: 15px 0px;
    }
    h3:not(:first-child) {
        font-size: 15px;
    }
    table {
        margin: 20px 0px;
        text-align: center;
        font-size: 15px;
        border-collapse: collapse;
        width: 100%;
        thead {
            td:not(#table2HeaderBlankSpace) {
                background-color: var(--GW-BRAND-COLOR-2);
                color: #fff;
                font-weight: 100;
                padding: 4px;
                border: 2px black solid;
            }
            td:first-child {
                width: 24%;
            }
            tr:last-child td {
                width: $cellWidth;
            }
        }
        tbody {
            td {
                border: 2px black solid;
                padding: 5px;
            }
            td:first-child {
                background-color: var(--GW-BRAND-COLOR-2);
                color: #fff;
                width: 24%;
            }
            td:not(:first-child) {
                width: $cellWidth;
            }
        }
    }
    @include gw-breakpoint-down(tablet) {
        table {
            font-size: 13px;
        }
    }
    @include gw-breakpoint-down(phone) {
        table {
            font-size: 10px;
        }
        td {
            padding: 0 !important;
        }
    }
}

.table2HeaderBlankSpace {
    background-color: #fff !important;
    border: none !important;
}

.table2FooterNote {
    span {
        font-weight: bold;
    }
    border: 1px black solid;
    padding: 5px;
}

.pncdAppendixFooterContainer {
    margin-top: 15px;
    ul li {
        margin: 5px 0px;
    }
    p:last-child {
        margin-top: 10px;
        font-weight: bold;
    }
}

.blankSpace {
    background-color: darkgray;
}