@import "~@jutro/theme/assets/sass/helpers";

.quoteInfoBar {
  --gw-quoteInfoBar-height: 72px;
  background: var(--GW-COLOR-SUCCESS-CONTRAST);
  box-shadow: var(--GW-SHADOW-2);
  width: 100%;
  height: var(--gw-quoteInfoBar-height);
}

.quoteWrapper {
  width: 88%;
  margin: 0 auto;
  display: flex;
  text-align: center;
  height: 100%;
}

.infoBlock {
  --gw-infoblock-max-width: 400px;
  --gw-infoblock-min-height: 64px;
  margin: var(--GW-LAYOUT-1) var(--GW-LAYOUT-12);

  max-width: var(--gw-infoblock-max-width);
  min-height: var(--gw-infoblock-min-height);
  flex: 1 1;
  padding: var(--GW-LAYOUT-3);
  display: flex;
  flex-direction: column;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
      padding-right: 0;
  }

  @include gw-breakpoint-down(phonewide) {
    align-items: center;
    justify-content: center;
  }
}

.infoBlockTitle {
  color: var(--GW-TEXT-COLOR-1);
}

.infoBlockContent {
  color: var(--GW-COLOR-WARNING-CONTRAST);
}

.coverableItem {
  display: block;
}

.infoBlockBadge {
  margin: 0 0 0 var(--GW-SPACING-3);
}

.infoOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
