
.payByMonthlyInstallmentsContentTitle{
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}
.payByMonthlyInstallmentsContentDescription {
    display: list-item;
    margin-left: 30px;
    list-style-type: disc;
  }

.payByMonthlyInstallmentsContentChildDescription {
    display: list-item;
    margin-left: 60px;
    list-style-type: circle;
}

.directDebitsMustBeSetup{
    background-color: "#ff0000" ;
}

.monthlyRepresentativeContent {
    border: 1px solid gray;
    padding: 12px;
    margin-bottom: 16px;
}

.monthlyRepresentativeContentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.checkboxFieldContainer {
    display: flex;
    align-items: baseline;
}
.checkboxField {
    span[role="checkbox"] {
        border: 2px solid var(--GW-COLOR-ERROR) !important;
    } 
}
@media (max-width: 768px) {
    .monthlyRepresentativeContentRow {
      width: 80vw !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
    }
}
#monthlyRepresentativeAnnualRepresentativeAPRValue {
    text-align: right;
}
.monthlyRepresentativeContentCaliculateRepresentativeAPR {
    margin-top: 12px;
}
.pleaseNoteText {
    color: var(--GW-BRAND-COLOR-2) !important;
    font-weight: bold !important;
}
.gw-bold-text {
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
}