@import "~@jutro/theme/assets/sass/helpers";
.amendDetailsButton {
    margin: 10px 5px 10px 0 !important;
    padding: 8px 12px;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1600 - 320)));
    &:hover:not(.disabled){
        span {
            color: var(--GW-TEXT-COLOR-3);
        }
    }
}

.coverageButton {
    margin: 5px;
}

.gridAlignment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.accordionTitleContainer {
    border: 1px solid gray!important;
    padding: 10px!important;
    margin-top: -25px!important;
    display: flex;
    font-size: var(--gw-quote-review-accordion-title-font-size);
    color: var(--gw-quote-review-accordian-title-color);
     @include gw-breakpoint-down(phone) {
        display: block;
     }
    justify-content: space-between;
}
.quoteReviewAccordion {
    border: 1px solid gray ;
    padding-top: var(--GW-SPACING-5);
}
  
.accordionTitleContainerText1 {
    flex: 2;
    width: 55%;
}  
.accordionTitleContainerText2 {
    flex: 4;
    width: 55%;
}  
.accordionTitleContainerText3 {
    flex: 3;
    text-align: end;
}

.accordionTitleContainerRight {
    text-align: right;
}

.accordionTitleContainerAddons {
    border: 1px solid gray!important;
    padding: 10px!important;
    margin-top: -25px!important;
    display: flex;
    font-size: var(--gw-quote-review-accordion-title-font-size);
    color: var(--gw-quote-review-accordian-title-color);
    justify-content: space-between;
}

.modListItems ul {
    padding-left: 0px !important;
    margin-left: 15px !important;
    margin-top: -15px;
    color: var(--GW-BRAND-COLOR-2);
    margin-bottom: 0px;
}

.modListItems li {
    padding-left: 15px; 
}

[id^="generalAfterMarketContainer"],
[id^="bodyWorkModsContainer"],
[id^="engineModsContainer"],
[id^="wheelsOrTyresModsContainer"],
[id^="conversionModsContainer"],
[id^="otherModsContainer"] {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    position: relative;
    box-sizing: inherit;
    margin-bottom: var(--gw-quote-modifications-margin-bottom);
}

@media screen and (max-width: 1024px) {
    [id^="generalAfterMarketContainer"],
    [id^="bodyWorkModsContainer"],
    [id^="engineModsContainer"],
    [id^="wheelsOrTyresModsContainer"],
    [id^="conversionModsContainer"],
    [id^="otherModsContainer"] {
        flex-direction: column;
        margin-bottom: 0px;
    }
}

[id^="generalAfterMarketLabelContainer"],
[id^="bodyWorkModsLabelContainer"],
[id^="wheelsOrTyresModsLabelContainer"],
[id^="engineModsLabelContainer"],
[id^="conversionModsLabelContainer"],
[id^="otherModsLabelContainer"] {
    flex: 1 1;
    align-items: flex-start;
    height: auto;
    text-align: left;
}

[id^="generalAfterMarketControlContainer"],
[id^="showHideBodyWorkControlContainer"],
[id^="showHideWheelsOrTyresControlContainer"],
[id^="showHideEngineControlContainer"],
[id^="showHideConversionControlContainer"],
[id^="showHideOtherControlContainer"] {
    overflow: auto;
    position: relative;
    width: 100%;
    flex: 2 2;
    overflow-wrap: break-word;
}

[id^="generalAfterMarketLbl"], 
[id^="bodyWorkModsLbl"],
[id^="engineModsLbl"],
[id^="wheelsOrTyresModsLbl"],
[id^="conversionModsLbl"],
[id^="otherModsLbl"] {
    font-size: var(--GW-FONT-SIZE-LABEL, 15px);
    color: var(--GW-BRAND-COLOR-2);
    font-weight: bold;
    flex: 1 1;
    align-items: flex-start;
    height: auto;
    text-align: left;
    justify-content: center;
    margin-bottom: 0px !important;
}

[class*="attachedModificationsButton"] {
    span {
        color: var(--GW-FIELD-COMPONENT-COLOR, #59646d) !important;
        outline: none;
    }
    background: white;
    font-weight: 100 !important;
    align-items: flex-start;
    border-radius: 0;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 5px 0 0 0;
}

[class*="attachedModificationsButton"]:hover {
    background: white !important;
    font-weight: 100 !important;
    align-items: flex-start;
    border-radius: 0;
    box-shadow: none;
    color: var(--GW-FIELD-COMPONENT-COLOR, #59646d) !important;
    text-decoration: none;
    outline: none;
}
