.correctFieldDisplay {
    display: block;
}

.removeClaimConvictionButton {
   margin-top: 10px;
}

.driver-actions-btn {
  margin-right: 10px;
}

.addClaimBtn {
  margin-bottom: 20px;
}