.paymentOptionsBox label{
        border: 1px solid var(--gw-quote-page-border-colour);
        border-radius: 5px;
        margin-bottom: var(--GW-SPACING-4);
        padding: 10px 10px 20px 10px;
        &:nth-of-type(2) label {
            border: none;
            margin-bottom: -15px;
            font-weight: normal;
            @media (max-width: 768px) {
                margin-bottom: -50px;
            } 
        }       
}
.transactionFee{
    display: list-item;
    input{
        display: none;
    }
    label{
        font-weight: normal;
    }
}
.displayItem{
    display: list-item;
}

.paymentPlans{
    font-size: var(--gw-monthly-representative-example-font-size, larger);
    width: 55vw;
}
@media (min-width: 200px) and (max-width: 768px) {
    .oneTimePaymentPlan {
      width: 73vw !important;
      font-size: var(--GW-FONT-SIZE-BODY) !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
    }
    .paymentPlans{
        font-size: larger;
    }
    .paymentOptionsBox label{
        width: 90vw
    }
}
.oneTimePaymentPlan{
    font-size: larger;
    width: 55vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.paymentLabel{
    margin-bottom: var(--GW-SPACING-4);
}

.premiumAmount {
    margin-left: auto;
}

.paymentOptionsBox {
    div div label {
        &>div:first-child {
            margin-top: var(--gw-payment-selection-icon-mt, 0px);
        }
    }
  }
