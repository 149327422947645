@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-tablet-sidebar-width: 20rem;
  --and-brand-header-logo-url: url("../../public/html/branding/logo-default.svg"); //placeholder value
}

html {
  --gw-default-header-image: url("../../public/html/branding/logo-default.svg");
  --gw-header-image: url("../../public/html/branding/tya/toyota-white-logo.svg");
  --gw-mobile-header-image: url("../../public/html/branding/logo-mobile.svg");
}

.qnbMain {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  min-height: 100vh;
}

.qnbMainContent {
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &::before, &::after  {
    display: none
  }
}

.faqPage {
  width: 70%;
  margin: auto;
}

// theme

$fonts-root: "../assets/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ----------------------------------------
//   BRANDING CSS MODULES
// ----------------------------------------
@import "../styles/branding/millennials-demo.scss";

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.headerLinkcomponent {
  padding-left: var(--GW-SPACING-8);
  text-align: right;
  color: var(--GW-TEXT-COLOR-3);
  span {
    font-size: var(--GW-FONT-SIZE-SM);
  }
  a {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    i, span {
      color: var(--GW-TEXT-COLOR-3);
      font-size: var(--GW-FONT-SIZE-H5);
    }
  }
  a:hover {
    color: var(--GW-TEXT-COLOR-3);
  }
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

.contactUsSection {
  position: absolute;
  top: 74px;
  background: var(--GW-BRAND-COLOR-2);
  width: 100%;
  z-index: 1;
  padding: 10px;
  color: #fff;
  P {
    margin-bottom: 10px !important;
  }
}
.talkdesk-button {
  position: fixed;
  right: var(--trigger-button-position-right, 20px);
  bottom: var(--trigger-button-position-bottom, 20px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--trigger-button-width, 64px);
  height: var(--trigger-button-height, 64px);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-image: var(--chat-icon, url(https://prd-cdn-talkdesk.talkdesk.com/cdn-assets/latest/talkdesk/product/app-icons/sms.svg));
  background-color: var(--GW-BRAND-COLOR-1);
  background-size: 56%;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color .1s ease-in-out;
}

@media screen and (min-height: 850px) {
  .talkdeskButtonExta {
    bottom: 100px !important;
  }
}


@media print {
  body:not(#popupBody):not(#quotePageContainer) {
    visibility: hidden;
  }  

  // Styles for Your Quote page print button
  #quotePageContainer, #quotePageContainer * {
    visibility: visible;
    font-size: 15px;
  }

  #quotePageContainer {
    width: 95%;
    top: 10px;
    left: 0;
    position: absolute;
  }

  // Styles for the DD Mandate print button
  #popupBody, #popupBody * {
    visibility: visible;
    font-size: 15px;
  }

  #contactInfoContainer {
    display: none;
  }
}
.ampReturnBtn{
  background-color: var(--GW-BUTTON-FILLED-BACKGROUND-COLOR);
  color: var(--GW-BRAND-COLOR-2);;
}
