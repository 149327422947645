.buttonContainer {
    --gw-wizard-sidebar-button-container-width: 75%;
    margin: var(--GW-SPACING-3) 0;
    display: flex;
    flex-direction: column;
    width: var(--gw-wizard-sidebar-button-container-width);
    margin: auto;
}

.actionButtons {
    margin-bottom: var(--GW-SPACING-3);
}

.actionButtons span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gwWizardWidePaneButton {
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    border-radius: 50px;
    border: solid var(--GW-BUTTON-OUTLINED-BORDER-WIDTH) !important;
  }