.gw-legal-and-payment-page-two-of-three-sub-title {
    border-bottom: 2px solid var(--gw-stepper-section-seperator);
    margin-bottom: 30px !important;
    padding-bottom: 10px;
}

.gw-legal-and-payment-page-two-of-three-section-bold {
    font-weight: 600;
}

.gw-is-card-registered-to-you-subtitle{
    font-weight: bold;
}