.monthlyRepresentative{
    margin-top: 20px;
    margin-left: -10px!important;
    text-align: right ;
    padding: 0px;
    display: table;
    width: 100%;  
}

@media (min-width: 200px) and (max-width: 768px) {
    .monthlyRepresentative {
        font-size: 15px !important;
        width: 75vw; 
    }
}

[id^="monthlyRepresentativeAnnualRepresentativeAPRKey"] {
    text-align: left;
}

.monthlyRepresentativeTitle{
    margin-bottom: 10px !important;
    text-align: left !important;
}

@media print {
    .monthlyRepresentativeContentRow {
        margin-bottom: 10px;
    }
  }

  .monthlyRepresentativeContentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.monthlyRepresentativeContentValueCol {
    font-weight: 700;
}