.tableContainer {
    table {
        background: white;
        border-collapse: collapse;
        tr th {
            font-weight: 400;
            padding: 3px;
            height: 30px;
            min-width: 24px;
        }
    }
}

.mandateValueTableCell {
    border: 1px solid black;
}

@media print {
    .tableContainer {
        table {
            tr th {
                padding: 1.5px;
                height: 15px;
                min-width: 12px;
            }
        }
    }
}