@import "~@jutro/theme/assets/sass/helpers";

$images-root: "/assets/images" !default;

// ...... @Eamon - We need to investigate the SCSS import issue at a later point

input {
  @include gw-breakpoint-down(tablet) {
    @supports (-webkit-touch-callout: none) {
      font-size: 16px;
    }
  }
}

#namedDriversOuterContainer {
  display: none;
}

#driverSummaryInfoHeader, #legal2of3InfoHeader {
  font-weight: bold;
  color: var(--GW-TEXT-COLOR-1);
  margin: 0 0 40px 0;
}

#driverContainer, #driverRemoveContainer {
  .gw-driver-component-casing div {
      column-gap: 5px;
  }
}

.gw-driver-date-of-birth {
  display: flex;
}

.gw-driver-date-of-birth-casing {
  display: inline-block;
}

.gw-driver-date-of-birth-casing div {
  font-weight: normal;
}

#driverSummaryInfoDriversMainDriverText {
  padding-bottom: 15px;
}

#driverSummaryInfoSubTitle  {
  color: var(--GW-TEXT-COLOR-1);
  margin-bottom: 40px;
}

#driverSummaryInfoPolicyTitle, #driverSummaryInfoDriversTitle {
  font-size: 1.8rem;
  color: var(--GW-TEXT-COLOR-1);
  line-height: 1.2;
  font-weight: normal;
  padding-bottom: 16px;
  border-bottom: 3px solid var(--gw-stepper-section-seperator);
  margin-bottom: 30px;
}

#changePolicyholder, #changeDriver {
  margin-bottom: 40px;
  width: var(--gw-button-branding-width);
}

#namedDriversContainer {
  display: inline-flex;
}

#addDriver, #changePolicyholder, #changeDriver, #removeDriver, #setMainDriver {
  margin-top: 10px;
  display: block;
  border: solid 1px !important;
  padding-top: 10px;
  padding-bottom: 30px;
}

#removeDriver, #setMainDriver {
  margin-left: 5px;
}

#driverSummaryInfoNamedDriversMainDriverText {
  margin: 30px 0 15px 0;
}

#addDriver {
  margin-right: 0;
  margin-left: auto;
  background: var(--GW-BRAND-COLOR-1);
  color: var(--GW-TEXT-COLOR-3);
}

.gw-accordion-titles span {
  color: var(--GW-BRAND-COLOR-2);
}

.gw-main-driver {
  font-size: x-large !important;
}
.gw-drivers-add-driver-button-container {
  display: block;
  height: 95px;
  padding-top: 10px;
}   

#policyDeleteDriverButton, #driverDeleteDriverButton {
  display: none;
}
// ...... @Eamon End of block to be moved permanently to : common\capabilities-react\gw-capability-quoteandbind-pm-react\pages\Drivers\DriversPage.module.scss

.gw-contact-details-button-container {
  display: flex;
  justify-content: space-between;
}

.gw-contact-details-section-quote-page {
  margin-top: 30px;
}

.andSubHeader {
  font-weight: var(--GW-FONT-WEIGHT-BOLD);
}

.gw-inline-loader {
  --gw-inline-loader-margin: 0 .5rem 0;
  display: inline-block;
  margin: var(--gw-inline-loader-margin);
}

.gw-wizard-title-container {
  display: none;
}

.gw-footer {
  @include gw-breakpoint-down(phone) {
    bottom: 0%;
    padding: 5px;
    margin-top: 30px;
    .footerLink{
      padding: 0px;
    }
  }
  --gw-footer-width: 92%;
  --gw-footer-bottom: 0;

  color: var(--gw-footer-text-colour);
  background-color: var(--GW-BACKGROUND-COLOR);
  height: var(--gw-footer-height);
  width: var(--gw-footer-width);
  padding: var(--gw-footer-padding);
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0%;
  left: 3%;

  & > div {
    width: 100%;
    display: inline-block;
    flex-direction: row;
    justify-content: space-between;
  }

  .footerItem {
    display: block;
    align-items: center;
  }

  .footerLink {
    @include gw-breakpoint-down(phone) { 
      padding: 2px;
    }
    --gw-footer-link-height: 20px;
    padding-right: 10px;
    padding-left: 10px;
    text-decoration: none;
    border-left: solid 1px var(--GW-TEXT-COLOR-1);
    height: var(--gw-footer-link-height);
    align-self: center;

    &:first-child {
      padding-left: 0;

      border-left: none;
    }

    a {
      text-decoration: none;
      color: var(--GW-TEXT-COLOR-1);

      &:hover {
        color: var(--GW-TEXT-COLOR-1);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .legalNoticeContainer {
    margin-top: 30px;
    p {
      color: var(--GW-TEXT-COLOR-1);
    }
  }
}

@media screen and (min-width: 1440px) {
  .gw-header {
      min-width: 1440px;
      padding: 0 190px;
  }
  .gw-contact-us-accordian-container {
    width: 22% !important;
  }
}

@media (min-width: 769px) {
  .gw-header{
    display: flex;
    align-items: center;
    justify-content: space-between;  
  }
}

@media (min-width: 1024px) and (max-width: 1439.95px) {
  .gw-header {
    padding: 0 60px;
  }
  .gw-contact-us-accordian-container {
    width: 22% !important;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .gw-contact-us-accordian-container {
    display: none;
  }

  [id*="quoteReviewInfoHeader"],  [class*="PMQuoteReview_quoteReviewAccordion"] {
    width: 90vw;
  }

  #divPreChatButton {
    right: 5% !important;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  .gw-header {
    max-width: 1024px;
    padding: 0 20px;
  }
  .gw-contact-us-accordian-container {
    width: 17.5% !important;
  }
}

@media (min-width: 0px) and (max-width: 639.95px) {
  .gw-header {
    padding: 0 15px;
  }
  .gw-image-versioning-logo {
    width: 196.33px !important;
    height: 35px !important;
  }
}

@media (min-width: 200px) and (max-width: 768px) {
  #quoteSectionContainerInner, [id*="chooseHowToPayHeaderTitleContainer"], [id*="monthlyorDirectDebitAndAnnualStatement"] {
    width: 90vw !important;
  }
  [id*="paymentOptions"] {
    width: 70vw !important;
  }
}

.gw-payment-cards-container {
  height: 77px;
  display: block;
  margin-bottom: 15px;
  @include gw-breakpoint-down(phone) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    > div {
      background-size: 85% 85%;
      background-repeat: no-repeat;
      background-position: right;
    }
    .gw-payment-visa, .gw-payment-mastercard {
      width: 85px;
      height: 55px;
    }
    .gw-payment-secure-payments {
      width: 127px;
      height: 85px;
    }
  }
}

.gw-payment-secure-payments {
  height: 100px;
  width: 150px;
  background-image: url('./../../../applications/quote-and-buy/public/payment-images/securePayments_AND.png');
  float: right;
  background-size: cover;
  margin-right: 5px;
}

.gw-payment-visa {
  height: 65px;
  width: 100px;
  background-image: url('./../../../applications/quote-and-buy/public/payment-images/visa_AND.png');
  float: right;
  background-size: cover;
  margin: 0 5px 0 5px;
}

.gw-payment-mastercard {
  height: 65px;
  width: 100px;
  background-image: url('./../../../applications/quote-and-buy/public/payment-images/mastercard_AND.png');
  float: right;
  background-size: cover;
  margin: 0 5px 0 5px;
}

.gw-bold {
  font-weight: bold;
}

.and-fontsize-16 {
  font-size: 16px;
}

.activatePortalHeader{
  line-height: var(--GW-LINE-HEIGHT-H1);
  font-size: var(--GW-FONT-SIZE-H1);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  color: var(--GW-BRAND-COLOR-2) !important;
}

#activatePortalConfirmationContainer {
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
}
#nextStepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rowStepContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#reviewDocsStepContainer , #commsStepContainer, #policyChangeStepContainer {
  display: flex;
  align-items: center;
  background: var(--gw-payment-complete-step-container-colour);
  color: var(--gw-payment-complete-step-container-text-colour, black);
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
}
.commsStep,
.policyChangeStep,
.reviewDocsStep {
  width: 10%;
  height: 80px;
  border: 1px;
  margin-right: 10px;
  padding-left: 80px;
  border-radius: 50%;
  background: var(--GW-TEXT-COLOR-3);
}
.commsStep {
  background: url('./../../../applications/quote-and-buy/public/icons/gw-icon-edit-disabled.png') 10px 9px no-repeat var(--GW-TEXT-COLOR-3);
  background-size: 60px;
}
.policyChangeStep {
  background: url('./../../../applications/quote-and-buy/public/icons/gw-icon-email-disabled.png')10px 9px no-repeat var(--GW-TEXT-COLOR-3);
  background-size: 60px;
}
.reviewDocsStep {
  background: url('./../../../applications/quote-and-buy/public/icons/gw-icon-search-disabled.png') 10px 9px no-repeat var(--GW-TEXT-COLOR-3);
  background-size: 60px;
}
.activatePortalBtn {
  margin-left: 40%;
}

@media (min-width: 0px) and (max-width: 768px) {
  .rowStepContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
  #nextStepsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 88vw ;
  }
  .activatePortalHeader{
    line-height: var(--GW-LINE-HEIGHT-H1);
    font-size: var(--GW-FONT-SIZE-H4);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    color: var(--GW-BRAND-COLOR-2) !important;
  }
  #paymentSuccessThankYouMessage{
    font-size: var(--GW-FONT-SIZE-H4);
  
  }
  #activatePortalConfirmationContainer {
    width: 88vw;
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
  }
  .activatePortalBtnContainer {
    display: flex;
    justify-content: center;
    width: 88vw ;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rowStepContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
  #nextStepsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 66vw ;
  }
  #activatePortalButtonContainer {
    display: flex;
    justify-content: center;
    width: 66vw ;
  }
}
@media (min-width: 601px) {
  #reviewDocsStepContainer, #commsStepContainer {
    width: 45%;
  }
  
  #policyChangeStepContainer {
    width: 45%;
    margin: 10px auto;
  }
}

.continueBtn {
  padding-inline: 5%;
}
.errorUserHasNoAccessDetails2 {
  color: var(--GW-BRAND-COLOR-1);
  text-decoration: underline;
}
.createPasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.inputPasswordWrapper {
  display: absolute;
}

.inputPasswordWrapper input {
  padding-right: 70px;
}

.inputPasswordWrapper .showPassword {
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;
  z-index: 1;
}
.gw-header {
  --gw-header-bottom: 0;
  --gw-header-image-height: 48px;
  --gw-header-image-width: 330px;
  --container-max-width: inherit;
  --test-image: var(--gw-header-branding-image);

  color: var(--GW-TEXT-COLOR-2);
  background-color: var(--gw-header-colour);
  height: var(--gw-header-branding-container-height);
  margin: 0 auto;
}

.gw-header-wrapper {
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: var(--GW-BACKGROUND-COLOR);
}

.gw-main-wrapper {
  padding-bottom: 0%;
}

.gw-content-wrapper, .gw-header-sticky {
  box-sizing: inherit;
}

.gw-container {
  width: var(--container-max-width);
}

.gw-vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gw-wizard-navigation-sidebar {
  margin-top: 40px;
}

#gw-contact-us-accordian {
  color: green;
}

.gw-contact-us-accordian-container {
  position: absolute;
  top: auto;
  display: block;
  width: 22%;
  background: var(--GW-BRAND-COLOR-2);
  z-index: 3;
  color: #ffffff;
}

.gw-contact-us-intro-text, .gw-contact-us-details, .gw-contact-us-number {
  color: var(--GW-TEXT-COLOR-3) !important;
  margin-bottom: 0px !important;
}

.gw-contact-us-intro-text {
  font-size: 0.77rem;
  padding-bottom: 3px !important;
}

.gw-contact-us-details {
  font-size: 0.9rem;
  padding-bottom: 3px !important;
}

.gw-contact-us-details:last-child {
  padding-bottom: 20px !important;
}

.gw-contact-us-number {
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.gw-vertical-align-item:first-child {
  padding-left: 0px;
}

.gw-image-versioning-logo {
  display: inline-block;
  width: var(--gw-header-branding-image-width);
  height: var(--gw-header-branding-image-height);
  background-image: var(--and-brand-header-logo-url, var(--gw-default-header-image));
  background-size: cover;
  @media (max-width: 768px) {
    width: var(--gw-header-branding-mobile-image-width);
    height: var(--gw-header-branding-mobile-image-height);    
  }
}

.stepperContainer {
  margin: 0 auto;
}

.stepperContainerHidden {
  display: none;
}

#step-progress-bar-panel {
  background-color: var(--gw-header-colour);
  border-radius: 0;
}

.gw-header-top-bar-button {
  margin-right: 40px;
}

.gw-phone-header-span {
  padding-left: var(--GW-SPACING-1);
}

.phone-header-grid {
  --gw-phone-header-grid-box-shadow: 0 0 10px 2px var(--GW-COLOR-WARNING-CONTRAST);

  grid-gap: var(--GW-SPACING-2);
  background-color: var(--GW-FOCUS-COLOR);
  padding: var(--GW-SPACING-2);
  overflow-y: scroll;
  overflow-x: hidden;
  width: var(--gw-phone-header-grid-width);
  position: absolute;
  box-shadow: var(--gw-phone-header-grid-box-shadow);
  right: inherit;
  height: 100%;
}

.gw-mobile-phonenumber{
  --gw-mobile-phonenumber-margin-top: -13px;
  --gw-mobile-phonenumber-margin-left: 13px;

  margin-top: var(--gw-mobile-phonenumber-margin-top);
  margin-left: var(--gw-mobile-phonenumber-margin-left);
  color: var(--GW-COLOR-INFO-CONTRAST);
  font-size: var(--GW-FONT-SIZE-H4);
}

.snapDrawerOpen {
  width: 100%;
  transition: transform 200ms linear, width 200ms linear;
  display: block;
}

.snapDrawer {
  display: none;
}

.snapDrawerOpenRight {
  right: 0;
  transform: translateX(0);
  @extend .snapDrawerOpen;
}

.snapDrawerOpenLeft {
  left: 0;
  transform: translateX(0);
  @extend .snapDrawerOpen;
}

.snapDrawerOpenTop {
  top: 0;
  transform: translateY(0);
  @extend .snapDrawerOpen;
}

.gw-phone-header {
  --gw-phone-header-grid-width: 0;
  font-size: var(--GW-FONT-SIZE-H4);
  top: 0;
  height: 100%;

  @extend .snapDrawer;

  &.gw-phone-header-open {
    --gw-phone-header-grid-width: 70%;

    @include gw-breakpoint-only(tablet) {
      --gw-phone-header-grid-width: 35%;
    }

    background-color: var(--GW-TEXT-COLOR-1);
    position: fixed;
    z-index: 500;
    @extend .snapDrawerOpenRight;
  }
}

.gw-phone-header-menu-link, .gw-phone-header-menu-link:visited {
  --gw-phone-header-menu-link-padding: 10px 17px;
    color:var(--GW-COLOR-INFO-CONTRAST);
    text-decoration: none;
    display: block;
    padding: var(--gw-phone-header-menu-link-padding);
    cursor: pointer;
    font-size: var(--GW-FONT-SIZE-H5);
}

.footer-border div {
  border-right: 1px solid var(--GW-BACKGROUND-COLOR-ELEMENT);
}

.footer-border div:last-child {
  border: none;
}

.gw-header-grid-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.gw-globalization-chooser {
  @include gw-breakpoint-up(tablet) {
    --gw-globalization-chooser-width: 20rem;
    width: var(--gw-globalization-chooser-width);
  }

  height: auto;
}

button#gw-wizard-cancel {
  display: var(--gw-cancel-button-display);
}

.gw-header-img {
  @include gw-breakpoint-down(tablet) {
    --gw-header-image-width: var(--GW-SPACING-8);
    --gw-header-image-height: var(--GW-SPACING-8);
    --gw-header-image: var(--gw-mobile-header-image, var(--gw-default-header-image));
  }
  width: var(--gw-header-image-width);
  height: var(--gw-header-image-height);
  background-image: var(--gw-header-image, var(--gw-default-header-image));
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

img.gw-header-img-mobile {
  --gw-header-image-width: 30px;
  --gw-header-image-height: 100%;
  @extend .gw-header-img
}

.gwPhoneMenu, .gwPhoneMenu:active, .gwPhoneMenu:focus, .gwPhoneMenu:focus:active, .gwPhoneMenu:hover, .gwPhoneMenu:focus:active:hover {
  --button-font-size: var(--GW-FONT-SIZE-H4);
  border: none;
  background: none;
  box-shadow: none;
}

.gw-no-coverages {
  color: var(--gw-gray-dark-4);
  padding-top: var(--GW-SPACING-6);
  padding-bottom: var(--GW-SPACING-6);
  margin-left: var(--GW-SPACING-5);
}

.alignRight {
  text-align: right;
}

.themeRoot {
  h1 {
    color: var(--GW-BRAND-COLOR-2) !important;
  }

  h2 {
    color: var(--GW-BRAND-COLOR-2) !important;
  }

  p {
    font-size: var(--GW-FONT-SIZE-BODY, 15px);
    margin-bottom: var(--gw-paragraph-mb, 0px);
  }

  label>span {
    font-size: var(--GW-FONT-SIZE-LABEL, 15px);
  }

  .wizardTitleOnly {
    line-height: var(--GW-LINE-HEIGHT-H4);
    font-size: var(--GW-FONT-SIZE-H4);
    color: var(--GW-BRAND-COLOR-2);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  }

  .wizardTitle {
    --gw-wizard-title-border-size: 2px;
    --gw-wizard-title-border-color: var(--GW-BORDER-COLOR);
    --gw-wizard-title-spacing: var(--GW-SPACING-4);
  
    border-bottom: var(--gw-wizard-title-border-size) solid var(--gw-wizard-title-border-color);
    padding: var(--gw-wizard-title-spacing) 0;
    margin-bottom: calc(var(--gw-wizard-title-spacing) * 2);
    @extend .wizardTitleOnly;
  }

  .wizardSubTitleContainer {
    margin-bottom: var(--GW-SPACING-5);
  }

  .pageTitle {
    font-size: var(--GW-FONT-SIZE-H1);
    line-height: var(--GW-LINE-HEIGHT-H1);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    color: var(--GW-BRAND-COLOR-2);
    margin-bottom: calc(2.25 * var(--GW-SPACING-4));
  }
}
.gw-mt {
  margin-top: var(--GW-SPACING-4) !important; //margin-top: 0.75rem = small gap
}
.gw-mb {
  margin-bottom: var(--GW-SPACING-4) !important; //margin-bottom: 0.75rem
}
.gw-ml {
  margin-left: var(--GW-SPACING-4) !important; //margin-left: 0.75rem
}
.gw-mr {
  margin-right: var(--GW-SPACING-4) !important; //margin-right: 0.75rem
}
.wizardTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include gw-breakpoint-down(phone) {
    flex-direction: column;
    align-items: normal;
  }
}

.phoneHeaderIconContainer {
  display: flex;
  justify-content: flex-end;
  @include gw-breakpoint-down(phone) {
    justify-content: center;
  }
}

button.actionIcon {
  min-width: var(--GW-SPACING-4);
  min-height: var(--GW-SPACING-4);
  width: var(--GW-SPACING-4);
  height: var(--GW-SPACING-4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: var(--GW-SPACING-5);
  color: var(--GW-TOOLTIP-ICON-COLOR);
  i {
    margin: 0;
    position: absolute;
  }
  svg {
    font-size: var(--GW-ICON-FONT-SIZE-3) !important;
  }
}

.gw-header-body-grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas: "header" "body";
}

.gw-payment-iframe {
  width: 100%;
  height: 1400px;
  border: 0;
  margin-left: 0;
}

$grid-positions: left, right, top, bottom, center, header, body;

@each $position in $grid-positions {
  .grid-slot-#{$position} {
    grid-area: unquote($position);
  }
}

$flow-directions: row, column, row-reverse, column-reverse;

@each $direction in $flow-directions {
  .flow-direction-#{$direction} {
    flex-direction: unquote($direction);
  }
}

.clauseContainer {
  --clause-border-size: 1px;
  --clause-border-color: var(--GW-BORDER-COLOR);

  border: var(--clause-border-size) solid var(--clause-border-color);

  &:not(:first-child) {
      margin-top: var(--GW-SPACING-2);
  }
}

.by_button {
  background-color: var(--GW-BRAND-COLOR-2) !important;
}

.gw-sub-section-title-seperator {
  border-bottom: solid 2px var(--GW-BRAND-COLOR-1);
  padding: var(--gw-header-section-padding);
  margin: var(--gw-header-section-margin) !important;
  span, p {
    font-size: var(--gw-section-title-font-size);
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
  }
}

#CarDetailsContainer {
    margin-bottom: 5px;
}

li [class*="GenericSelectControl"] {
  overflow: visible;
  line-height: 1 !important;
}

#quoteRecalculationContainer {
    p{
        margin-bottom: 10px;
    }
    div {
        margin-bottom: 2px;
    }
}

#documentContainer {
    margin-top: 10px;
    margin-bottom: 10px;
}

.documentLink {
    display: block !important;
    margin-bottom: var(--gw-document-link-margin-bottom, 0);
}

.dropdown-field-wrap {
  div:first-child {
    @-moz-document url-prefix() {
      @include gw-breakpoint-down(phone) {
        @supports(white-space: pre-wrap) {
          white-space: pre-wrap;
        }
        @supports(text-wrap: wrap) {
          text-wrap: wrap;
        }
      }
    }
  }
}

button#gw-wizard-previous {
  width: var(--gw-back-button-width);
  @include gw-breakpoint-down(tablet) {
   margin-top: var(--gw-kfi-back-button, 0);
  }
}

#CarRegistrationNumberContent {
  margin-bottom: 0 !important;
  font-weight: var(--gw-lp1-car-reg-content-font-weight);
}

#matchedCarDetailsContent {
  font-weight: var(--gw-lp1-car-reg-content-font-weight);
}

#CarRegistrationNumberContent::after {
  content: ' *';
  color: var(--GW-COLOR-ERROR);
}

.yourVehicleHeader {
  font-weight: 400 !important;
  font-size: 1em;
}

.gw-enhanced-vehicle-detail {
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 10px;
}

#WeCouldNotFindThisCar {
  color: var(--GW-COLOR-ERROR);
}

.gw-quote-calculation-text {
  font-weight: 500;
}

.gw-quote-calculation-text-checkbox [class*="CheckboxField__inlineLabel"] {
  font-weight: normal;
}

#AggMessageContainer {
  margin-top: calc(20vh - 12vh);
}

#AggLowerMessageContainer {
  margin-top: 1vh;
}

#AggslogoImageContainer {
  margin: 0 auto;
}
#AggsLoaderContainer {
  margin: 0 auto;
  padding-bottom: 80px;
}
#AggsHeaderText {
  text-align: center;
}
.newQuoteButtonContainer {
  width: 70%;
}
#AggsSubSpinnerText {
  text-align: center;
}
#AggsHeader {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;
}

.jut__Accordion__cardTitle {
  @media (max-width: 640px) {
    font-size: 0.7rem;
  }
}
.gw-decline-error-title {
  font-size: 2.5em !important;
  font-weight: 400 !important;
  margin-top: 60px !important;
  margin-bottom: 30px !important;
}
.gw-decline-error-text {
  font-weight: 400 !important;
  font-size: 1.3em !important;
}
.gw-decline-error-text-reduced {
  font-weight: 400 !important;
  font-size: 1.1em !important;
}
.gw-how-did-you-hear-about-us {
  display: block;
}

.loader {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 8px solid var(--GW-BRAND-COLOR-2);
  border-top: 8px solid var(--GW-BRAND-COLOR-1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.jut__Button__filled {
   font-weight: 500;
}

.jut__Accordion__cardTitle {
    @include gw-breakpoint-down(phone) {
        font-size: 0.9rem;
    }
}

.jut__ModalHeader__modalHeaderLayout {
    border-bottom: 0px;
}

.jut__InputField__input.disabled {
  background-color: var(--gw-disabled-input-background-color, #ffffff);
}

.tick-icon {
  filter: var(--gw-quote-tick-color);
}

[class*="PMQuotePage_Coverage_covBenefitContainer"] {
  align-items: var(--gw-quote-tick-alignment, flex-start) !important;
}

.newDriverActionsSection {
  margin-top: 48px;
}

.gw-quote-main-driver {
  font-size: 24px;
}

.gw-quote-additional-driver {
  font-size: 24px;
  margin-top: 15px;
}

.PMQuotePageCustomHref {
  color: var(--GW-BRAND-COLOR-1);
  text-decoration: underline;
  cursor: pointer;
}

li.activateYourPortalListElement {
  list-style-type: var(--gw-activate-your-portal-list-style-type);
  padding-left: 0px;
}

li.activateYourPortalListElement::before {
  content: var(--gw-activate-your-portal-list-item-icon, "✔");
  padding-right: 10px;
}

hr.themedHorizontalLine {
  border: none;
  border-top: solid 2px var(--GW-BRAND-COLOR-1, var(--GW-BORDER-COLOR));
  margin: var(--GW-LAYOUT-2) 0; 
}  