.gwIssuesContainer {
  --gw-issues-message-border: 100%;

  margin-bottom: var(--GW-LAYOUT-5);

  .gwIssuesMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--GW-BORDER-RADIUS);
  }

  .gwIssuesError {
    border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
  }

  .gwIssuesWarning {
    border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-WARNING);
    background-color: var(--GW-COLOR-WARNING);
  }

  .gwIssuesMessageContainer {
    display: flex;
    align-items: center;
    padding: var(--GW-LAYOUT-4);
  }
}
.gwIssuesWarning {
   background-color: var(--GW-COLOR-WARNING);
   padding-right: var(--GW-SPACING-3);
}

.gwIssuesError {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  padding-right: var(--GW-SPACING-3);
}

