.helpIcon {
  --button-position: var(--GW-SPACING-8);
  --button-size: 56px;
  --button-bg-color: var(--GW-COLOR-INFO);
  --icon-position: var(--GW-SPACING-8);

  border-radius: 50%;
  bottom: var(--button-position);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 0;
  right: var(--icon-position);
  height: var(--button-size);
  width: var(--button-size);

  &:hover {
    --button-hover-bg-color: var(--GW-COLOR-INFO);
  }
  &:active {
    --button-active-color: var(--GW-COLOR-INFO);
  }
  &:focus {
    --button-focus-border-color: var(--GW-COLOR-INFO);
  }
  i {
    --gw-help-icon-size: 1.5rem;
    font-size: var(--gw-help-icon-size);
  }
}