.infoBlock {
    width: 25%;
    padding: var(--GW-SPACING-3);
    display: flex;
    flex-direction: column;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}