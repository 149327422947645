.oldValue {
    color: var(--GW-TEXT-COLOR-2);
    text-decoration: line-through;
    margin-right: var(--GW-SPACING-4);
}

.newValue {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.policyChangesSection:not(:last-child) {
    margin-bottom: var(--GW-SPACING-6);
}

.policyChangesSectionTitle {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    padding: var(--GW-SPACING-5) 0;
  }
