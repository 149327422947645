@import "~@jutro/theme/assets/sass/helpers";
.andExcessTable {
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0px;

  table,
  td,
  th {
    border: 1px solid grey;
  }

  td,
  th {
      @include gw-breakpoint-down(phone) {
          padding: 0px
      }
    padding: 10px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .andExcessTableContainer {
    width: 90vw;
  }
}

.andInfoIcon{
  color: var(--GW-TOOLTIP-ICON-COLOR);
  font-size: larger;
  &:hover {
    cursor: pointer;
  }
}

.voluntaryExcessDescription2{
  margin: 8px 0 8px 0;
}