.gwClassOfUseVehicleRadio {
    #classOfUse0{
        label {
            padding: 10px 0;
            margin-bottom: 5px;
            border: 1px solid var(--GW-BORDER-COLOR);
        }
    }
}

.gw-quote-car-modified-labels {
    font-size: var(--GW-FONT-SIZE-LABEL, 15px);
    font-weight: bold;
}
  
[id*="carBeenModifiedContainer"] {
    margin-bottom: 15px;

    ul {
        margin-bottom: 3px !important;
    }
}
  
.addModifiedCarButton {
    width: 175px !important;
    margin-bottom: var(--gw-modification-button-margin-bottom);
}

.addModifiedCarButtonWide {
    width: 130px !important;
    margin-bottom: var(--gw-modification-button-margin-bottom);
}

p.gw-quote-car-modified-labels::first-letter {
    color: red;
}

[class*="ValueContainer"] {
    flex-direction: column;
    align-items: flex-start !important;
}

[class*="Tag__tag"] {
    margin-top: 10px;
}

[class*="TagCollection__collection"] {
    flex-direction: column;
    align-items: flex-start !important;
}

[id^="generalAftermarketAccessories"]:before,
[id^="bodyWorkModifications"]:before,
[id^="engineModifications"]:before,
[id^="wheelsOrTyreModifications"]:before,
[id^="otherModifications"]:before {
    content: "*";
    color: var(--gw-required-branding-asterisk-color, #b94a48);
}

[id^="vehicleHasBeenModifiedContainer"] {
    [class*="Icon__iconSize"] {
        font-size: 1.5rem;
        color: var(--GW-BUTTON-FILLED-COLOR);
        background-color: var(--GW-BRAND-COLOR-2);
        border-radius: 20px;
    }

    span {
        [class*="Tag__label"] {
            max-width: 100%;
            margin-right: 15px;
        }
    }

    @media (max-width: 500px) {
        span {
            [class*="Tag__label"] {
                max-width: var(--gw-chosen-mod-button-width-percentage);
                margin-right: 8px;
                white-space: normal !important;
            }
        }
    }
    
    [class*="IconButton__icon"] {
        font-size: 2rem !important;
        color: #000 !important;
    }
    
    [class*="Tag__deleteButton"] {
        height: 20px;
        width: 20px;
    }
    
    [class*="GenericSelectControl__control"] {
        max-height: 100% !important;
    }

    [class*="GenericSelectControl__control"] > div:first-child {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    [id*="generalAfterMarketMods"],
    [id*="bodyWorkMods"],
    [id*="engineMods"],
    [id*="wheelsOrTyresMods"],
    [id*="otherMods"]  {
        flex-direction: row;
    }
}