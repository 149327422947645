@import "~@jutro/theme/assets/sass/helpers";

.gw-contact-details-button-quote {
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    border-radius: 50px;
    border: solid var(--GW-BUTTON-OUTLINED-BORDER-WIDTH) !important;
  }

  #contactDetailsInfoHeader {
    margin-top: 30px;
  }