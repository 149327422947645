@import "~@jutro/theme/assets/sass/helpers";
.startDate {
    width: auto;
    padding: 0;
    align-items: baseline;

    @include gw-breakpoint-down(phonewide) {
      display: flex;
      flex-direction: row;
    }

    div:first-child {
      flex: inherit;
    }
  }
  .quotePageHeader {
    @include gw-breakpoint-down(phonewide) {
      flex-direction: row;
      align-items: center;
    }
  }