.ampEntryLoader {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 20px;
    width: 80px;
    white-space: nowrap;
    opacity: 1;
    transition: opacity 0.5s linear;
    transition-delay: 0.2s;
  
    & > div,
    &::after,
    &::before {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-bottom: 20px;
      background: var(--GW-BRAND-COLOR-1);
      border-radius: 100%;
      animation: bounce 1.4s infinite ease-in-out both;
  
      @keyframes bounce {
        0%, 80%, 100% {
          transform: scale(0);
        }
        40% {
          transform: scale(1.0);
        }
      }
    }
  
    &::after,
    &::before {
      content: " ";
    }
  
    &::after {
      animation-delay: 0.32s;
      margin-left: 20px;
    }
  
    &::before {
      animation-delay: -0.32s;
      margin-right: 20px;
    }
  }