.PurchaseFailedContainer {
    --gw-purchase-failed-container-width: 70%;
    --gw-purchase-failed-title-margin: 40px 0;
    --gw-purchase-failed-sizing: 10px;
    width: var(--gw-purchase-failed-container-width);
    margin: auto;
}

p.pageTitle {
    margin: var(--gw-purchase-failed-title-margin);
    margin-bottom: 20px;
    font-size: xxx-large;
    font-weight: 450;
}

.icon {
    margin-right: var(--gw-purchase-failed-sizing);
}

.assistanceText {
    padding: var(--gw-purchase-failed-sizing) 0;
    font-weight: 500;
    font-size: larger;
}

.highlightText span {
    color: var(--GW-BRAND-COLOR-2);
    font-weight: bold;
}
.warningBoldText{
    color: var(--GW-BRAND-COLOR-2);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);;
}