// Black
@font-face {
    font-family: 'BlackKFI';
    src: url('#{$sinkin-sans-root}/SinkinSans-800Black.ttf') format('truetype');
    font-style: normal;
    font-stretch: normal;
    font-weight: 750;
  }


  // Black Italic
  @font-face {
    font-family: 'BlackItalic';
    src: url('#{$sinkin-sans-root}/SinkinSans-800BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-stretch: normal;
    font-weight: 750;
  }
  
  // Bold
  @font-face {
    font-family: 'HeadersBold';
    src: url('#{$sinkin-sans-root}/SinkinSans-700Bold.ttf') format('truetype');
    font-style: normal;
    font-stretch: normal;
    font-weight: 700;
  }
  
  // Bold Italics
  @font-face {
    font-family: 'BoldItalic';
    src: url('#{$sinkin-sans-root}/SinkinSans-900XBlackItalic.ttf') format('truetype');
    font-style: italic;
    font-stretch: normal;
    font-weight: 700;
  }
  
  // Book (body + short headlines)
  @font-face {
    font-family: 'BodyFont';
    src: url('#{$sinkin-sans-root}/SinkinSans-200XLight.ttf') format('truetype');
    font-style: normal;
    font-stretch: normal;
  }
 
  // Book Italics
  @font-face {
    font-family: 'BodyItalicFont';
    src: url('#{$sinkin-sans-root}/SinkinSans-200XLightItalic.ttf') format('truetype');
    font-style: italic;
    font-stretch: normal;
  }

  // Regular (headlines)
  @font-face {
      font-family: 'kfiRegular';
      src: url('#{$sinkin-sans-root}/SinkinSans-400Regular.ttf') format('truetype');
      font-style: normal;
      font-stretch: normal;
      font-weight: 350;
  }

  // Light
  @font-face {
      font-family: 'Light';
      src: url('#{$sinkin-sans-root}/OTF/SinkinSans-300Light.otf') format('opentype');
      font-style: normal;
      font-stretch: normal;
  }
