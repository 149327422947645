@import "~@jutro/theme/assets/sass/helpers";
.gw-accountIcon-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.driver-subtext {
    margin-bottom: 10px;
}

.changeButton {
    margin-bottom: var(--GW-SPACING-4);
    margin-top: -20px;
    @include gw-breakpoint-down(tablet) {
       display: block;
       margin-bottom: 30px;
    }
}
.addDriverButton {
    float: right;
    @media (max-width: 768px) {
        float: none;
    }
}
.addDriverButtonContainer {
    margin-bottom: 60px !important;
    @media (max-width: 768px) {
        margin-bottom: var(--GW-SPACING-4) !important;
    }
}

.driversPageDriverName {
    color: var(--GW-BRAND-COLOR-2) !important;
}

.person-icon {
    font-size: 25px;
    margin-top: 10px;
}
