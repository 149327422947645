
.andCoverage {
  .andLabel {
    font-size: small;
  }

  .andPncdIncluded {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 12px;;
  }
  
  .andChoice {
    display: flex;
    padding: 16px;
    margin-top: 8ps;
    margin-bottom: var(--GW-SPACING-4);
    border-radius: 2px;
    border: 1px solid;
    border-color: var(--gw-quote-page-border-colour);

    input[type="radio"] {
      display: flex;
      appearance: none;
      margin-right: 0.5em;
      margin-top: 3px;
      width: var(--GW-RADIO-BUTTON-SIZE);
      height: var(--GW-RADIO-BUTTON-SIZE);
      border: 1px solid var(--GW-SELECTOR-SELECTED-BACKGROUND-COLOR);
      border-radius: 50%;
      place-content: center;
  
    }
    input[type="radio"]::before{
      content: "";
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  
    input[type="radio"]:checked::before{
      background-color: var(--GW-SELECTOR-SELECTED-BACKGROUND-COLOR);
      border-radius: 50%;
    }
    .ncdTextContainer {
      margin-left: 35px;
      margin-top: -18px;
    }
  }

  .andChoiceRedBorder {
    border-color: var(--GW-COLOR-ERROR);
    background-color: var(--GW-COLOR-ERROR-BACKGROUND-TINT);
  }


}

h2.pncdHeader {
  padding-top: 15px;
}

.andCoverageNameLabel {
  margin-top: -3px !important;
  color: var(--gw-coverage-highlighted-text-colour);
}

.andStepBack {
  color: var(--GW-BRAND-COLOR-1);
  text-decoration: underline;
  cursor: pointer;
}

.andPNCDIconContainer {
  display:flex; 
  flex-direction: row;
  margin: 25px 0 10px 0;
}

.andPNCDIcon {
  height: 60px; 
  width: 60px; 
  margin-right: 15px;
}

.NCDPSVGIcon {
  .covIconLayer1 {
    fill: #f7f7f7;
  }
  .covIconLayer2 {
    fill: #efefef;
  }
  .covIconLayer3 {
    fill: var(--gw-coverage-icon-colour);
  }
  .covIconLayer4 {
    fill: #fff;
  }
  .covIconLayer5 {
    fill: #fff;
  }
  .covIconLayer6 {
    fill: #fff;
  }
  .covIconLayer7 {
    fill: #fff;
  }
  .covIconLayer8 {
    fill: var(--gw-coverage-icon-colour);
  }
  .covIconLayer9 {
    fill: var(--gw-coverage-icon-colour);
  }
}

