@import "~@jutro/theme/assets/sass/helpers";


.startDate {
  width: auto;
  padding: 0;
  align-items: baseline;

  @include gw-breakpoint-down(phonewide) {
    display: flex;
    flex-direction: row;
  }

  div:first-child {
    flex: inherit;
  }
}
.quotePageHeader {
  @include gw-breakpoint-down(phonewide) {
    flex-direction: row;
    align-items: center;
  }
}
a:hover {
  color: var(--GW-BRAND-COLOR-1);
}

.quotePriceBox {
  position: sticky;
  top: 0;
  z-index: 100;
}

.andQuoteRow {
  display: flex;
  width: 100%;
}

.andQuoteOfferingHeader {
  text-align: center;
  display: flex;
  justify-content: space-around;
  background: var(--GW-BACKGROUND-COLOR);
}

.andQuoteCellOffering {
  border: 2px solid;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: table-column;
  align-items: baseline;
  justify-items: center;
  justify-content: center;
  border-color: var(--gw-your-branding-quote-box-border);
  background: var(--GW-BRAND-COLOR-2);
  color:var(--GW-TEXT-COLOR-3);
  font-size: 1.4em;
}

.andAnnualPremiumAmount {
  margin: 0 auto;
  color: var(--gw-quote-price-amount-text-colour);
  font-size: 1.7em;
  font-weight: bold;
}

.andAnnualPremiumTitle {
  margin: 0 auto;
}

.andYourQuoteHeaderSubtitle2 {
  margin-bottom: 1.5rem !important;
}

.yourQuoteHeaderSubtitle2KFI {
  color: var(--GW-BRAND-COLOR-2);
  font-weight: bold;
}


.andPrivacyNoticeLink {
  text-decoration: underline;
  color: var(--GW-BRAND-COLOR-1);
  cursor: pointer;
}
.quoteLoader {
  position: relative;
  display: inline-block;
  margin-left: 125px;
  height: 20px;
  width: 80px;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.5s linear;
  transition-delay: 0.2s;

  & > div,
  &::after,
  &::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
    background: var(--GW-BRAND-COLOR-1);
    border-radius: 100%;
    animation: bounce 1.4s infinite ease-in-out both;

    @keyframes bounce {
      0%, 80%, 100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1.0);
      }
    }
  }

  &::after,
  &::before {
    content: " ";
  }

  &::after {
    animation-delay: 0.32s;
    margin-left: 20px;
  }

  &::before {
    animation-delay: -0.32s;
    margin-right: 20px;
  }
}
.quotePriceFrequency {
  margin-left: -200px !important;
}