.successDetailsPage {
    --gw-success-details-page-max-width: 90em;
    margin: 0 auto;
    max-width: var(--gw-success-details-page-max-width);
}

.successDetailsContainer {
    --gw-success-details-container-padding-top-bottom: 40px;
    --gw-success-details-container-padding-right-left: 50px;
    padding: var(--gw-success-details-container-padding-top-bottom) var(--gw-success-details-container-padding-right-left);
}

.accordion {
    margin-bottom: var(--GW-LAYOUT-6);
}
.accordionCardHeader {
    --accordion-height: auto;
}
.accordionCardBody {
    margin-right: 0;
    margin-left: 0;
}

.removeLinkStyle {
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
    &:hover {
      text-decoration: none;
      color: var(--GW-BRAND-COLOR-1-ACTIVE);
    }
}


.currencyStyle {
    margin-top: var(--GW-LAYOUT-4);
}

.policyPeriod{
    grid-row-gap: var(--GW-LAYOUT-4);
}